<template>
    <div class="warp" v-loading="Loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="main">
            <div class="page page-1 active">
                <div>
                    <div class="page1_left">
                        <p style="text-align:center;padding-top:53px;margin-left:1px;"><span
                                style="background:#3f5d06;color:#d9e956;padding:2px 3px;border-radius:4px;font-family:sans-serif;">如任务已达标
                                但无法领取 尝试小退角色</span></p>
                        <!-- 角色绑定区域 -->
                        <div class="page1_left_top">
                            <p v-if="info.charac_no <= 0" class="button_login" @click="LoginDisplay = 'display:block'">
                            </p>
                            <div v-else style="position:absolute;width:228px;margin-top:80px;">
                                <ul>
                                    <li>当前角色：{{ info.charac_name }}</li>
                                </ul>
                                <p><span style="color:#777;" @click="LoginDisplay = 'display:block'">《切换》</span> </p>
                            </div>
                        </div>
                        <!-- 角色绑定区域 end -->
                        <div class="page1_left_bottom" style="margin-top:20px;">
                            <ul>
                                <li>
                                    <div class="content_act_left">
                                        <img src="../../../public/static/plug/growth/static/images/other/10105.png"
                                            style="width: 28px;"
                                            v-on:mouseover="lj_info($event, info.plugGrowthInfo.lb_1)"
                                            v-on:mouseout="removeActive($event)">
                                    </div>
                                    <div style="display:inline-block;margin-top:11px;width:51%;margin-left:10px;">
                                        <span style="color: rgb(117, 187, 6);">角色首绑成长礼包</span>
                                    </div>
                                    <div
                                        style="display:inline-block;vertical-align:middle;position:absolute;left: 166px;top: 309px;">
                                        <div v-if="info.lb.first == 1">
                                            <img v-if="info.lb.lb_1 == 0"
                                                src="../../../public/static/plug/growth/static/images/1.png"
                                                @click="receive('', 2)">
                                            <span v-if="info.lb.lb_1 == 1"
                                                style="margin-left:7px;position:relative;top:6px;right: -5px;">已领取</span>
                                        </div>
                                        <div v-else>
                                            <img src="../../../public/static/plug/growth/static/images/4.png">
                                        </div>
                                    </div>
                                </li>
                                <li style="margin-top:19px;">
                                    <div class="content_act_left">
                                        <img src="../../../public/static/plug/growth/static/images/other/10105.png"
                                            style="width: 28px;"
                                            v-on:mouseover="lj_info($event, info.plugGrowthInfo.lb_2)"
                                            v-on:mouseout="removeActive($event)">
                                    </div>
                                    <div style="display:inline-block;margin-top:11px;width:51%;margin-left:10px;">
                                        <span style="color: rgb(117, 187, 6);">每日登录礼包</span>
                                    </div>
                                    <div
                                        style="display:inline-block;vertical-align:middle;position:absolute;left: 166px;top: 369px;">
                                        <div v-if="info.lb.lb_2 != info.time">
                                            <img src="../../../public/static/plug/growth/static/images/1.png"
                                                @click="receive('', 3)">
                                        </div>
                                        <div v-else-if="info.lb.lb_2 == info.time">
                                            <span
                                                style="margin-left:7px;position:relative;top:6px;right: -5px;">已领取</span>
                                        </div>
                                        <div v-else>
                                            <img src="../../../public/static/plug/growth/static/images/4.png">
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="page1_right">
                        <div style="padding:23px 6px 0px 2px;">
                            <ul class="zhoj">
                                <li v-for="(v, i) in info.cz_level" :key="i">
                                    <div class="content_act_left">
                                        <img :src="v.ioc"
                                            style="width: 28px;border:2px solid #ffba00;border-radius:4px;"
                                            v-on:mouseover="lj_info($event, v.code)"
                                            v-on:mouseout="removeActive($event)">
                                    </div>
                                    <div style="display:inline-block;margin-top:11px;width:60%;margin-left:10px;">
                                        <span style="color: rgb(117, 187, 6);">达成Lv{{ v.level }}</span>
                                    </div>
                                    <div
                                        style="display:inline-block;vertical-align:middle;position:relative;right:-6px;top:-2px;">
                                        <div v-if="info.roleinfo.lev >= v.level">
                                            <img v-if="v.receive == 0"
                                                src="../../../public/static/plug/growth/static/images/1.png"
                                                @click="receive(v.level, 1)">
                                            <span v-if="v.receive == 1" style="margin-left:7px;">已领取</span>
                                        </div>
                                        <div v-else>
                                            <img src="../../../public/static/plug/growth/static/images/4.png">
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="dibu_bottom">
                            <ul>
                                <li>
                                    <div class="content_act_left">
                                        <img src="../../../public/static/plug/growth/static/images/other/10105.png"
                                            style="width: 28px;"
                                            v-on:mouseover="lj_info($event, info.plugGrowthInfo.lb_tx_1)"
                                            v-on:mouseout="removeActive($event)">
                                    </div>
                                    <div style="display:inline-block;margin-top:11px;width:44%;margin-left:10px;">
                                        <span style="color: rgb(117, 187, 6);">通关任意副本最佳记录</span>
                                    </div>
                                    <div style="display:inline-block;margin-top:11px;width:13%;text-align:center;">
                                        <span style="color: rgb(117, 187, 6);">{{ info.blood }}/{{
                                        info.plugGrowthInfo.lb_count_1
                                        }}</span>
                                    </div>
                                    <div
                                        style="display:inline-block;vertical-align:middle;position:absolute;right: 8px;top:309px;">
                                        <div
                                            v-if="info.lb.lb_tx_1 == 0 && info.blood >= info.plugGrowthInfo.lb_count_1">
                                            <img src="../../../public/static/plug/growth/static/images/1.png"
                                                @click="receive('', 5)">
                                        </div>
                                        <div v-else-if="info.lb.lb_tx_1 == 1">
                                            <span
                                                style="margin-left:7px;position:relative;top:6px;right:4px;">已领取</span>
                                        </div>
                                        <div v-else>
                                            <img src="../../../public/static/plug/growth/static/images/4.png">
                                        </div>
                                    </div>
                                </li>


                                <li style="margin-top:24px;">
                                    <div class="content_act_left">
                                        <img src="../../../public/static/plug/growth/static/images/other/10105.png"
                                            style="width: 28px;"
                                            v-on:mouseover="lj_info($event, info.plugGrowthInfo.lb_tx_2)"
                                            v-on:mouseout="removeActive($event)">
                                    </div>
                                    <div style="display:inline-block;margin-top:11px;width:44%;margin-left:10px;">
                                        <span style="color: rgb(117, 187, 6);">完成{{ info.plugGrowthInfo.lb_count_2
                                        }}条角色成长任务</span>
                                    </div>
                                    <div style="display:inline-block;margin-top:11px;width:13%;text-align:center;">
                                        <span style="color: rgb(117, 187, 6);">{{ info.level_wc_count }}/{{
                                        info.plugGrowthInfo.lb_count_2
                                        }}</span>
                                    </div>
                                    <div
                                        style="display:inline-block;vertical-align:middle;position:absolute;right: 8px;top:368px;">
                                        <div v-if="info.level_wc_count >= info.plugGrowthInfo.lb_count_2">
                                            <img v-if="info.lb.lb_tx_2 == 0"
                                                src="../../../public/static/plug/growth/static/images/1.png"
                                                @click="receive('', 4)">
                                            <span v-if="info.lb.lb_tx_2 == 1"
                                                style="margin-left:7px;position:relative;top:6px;right:4px;">已领取</span>
                                        </div>
                                        <div v-else>
                                            <img src="../../../public/static/plug/growth/static/images/4.png">
                                        </div>
                                    </div>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="iteminfo">
            <div style="text-align:center;">物品礼包</div>
            <div v-for="(itmec, i) in itme_show_data" :key="i" style="padding:0px 6px;">{{ itmec.name }}x{{ itmec.num }}
            </div>
        </div>
        <div class="alert">
            <!-- 弹出框 -->
            <div class="alert_bg" :style="MessageDisplay">
                <div class="alert_main">
                    <div class="alert_title"><span>提示</span></div>
                    <div class="alert_con">
                        <div class="alert_com">
                            <span>{{ MessageMsg }}</span>
                            <div class="btn" v-if="MessageMsg == '当前绑定角色人数已达上限'" @click="CloseAlert2">关闭</div>
                            <div class="btn" v-else-if="MessageMsg != '当前绑定角色人数已达上限'" @click="CloseAlert">关闭</div>
                        </div>
                    </div>
                    <div class="alert_bottom"><span></span></div>
                </div>
            </div>
            <!-- 弹出框end -->
            <!-- 登陆框 -->
            <div class="alert_bg" :style="LoginDisplay">
                <div class="alert_main" style="margin-top:86px;width:350px;">
                    <div class="alert_title"><span>绑定|切换</span></div>
                    <div class="alert_con">
                        <div class="alert_com">
                            <p style="margin-bottom:6px;display:flex;"><span
                                    style="padding:0px 0px 0px 42px;">游戏账号：</span><input type="text"
                                    v-model="login_info.username" class="service__input"></p>
                            <p style="margin-bottom:6px;display:flex;"><span
                                    style="padding:0px 0px 0px 42px;">游戏密码：</span><input type="password"
                                    v-model="login_info.password" class="service__input"></p>
                            <p style="margin-bottom:6px;display:flex;"><span
                                    style="padding:0px 0px 0px 42px;">绑定角色：</span>
                                <select v-model="login_info.role" style="width:154px;">
                                    <option v-for="(itmec, i) in role_data" :key="i" :value="itmec.charac_no">
                                        {{ itmec.charac_name }}</option>
                                </select>
                                <span style="background:#7478e4;padding:3px 10px;margin-left:2px;border-radius:4%;"
                                    @click="getRole">获取</span>
                            </p>
                            <div>
                                <span style="padding:0px 0px;color:#f90000;"><span
                                        style="display:inline-block;padding:0px 0px;">{{ LoginError }}</span></span>
                            </div>
                            <div style="text-align:center;padding:10px 0px">
                                <div class="btn" @click="login" style="margin:0px 0px;display:inline-block;">绑定</div>
                                <div class="btn" @click="LoginDisplay = 'display:none'"
                                    style="margin:0px 0px;display:inline-block;">取消</div>
                            </div>
                        </div>
                    </div>
                    <div class="alert_bottom"><span></span></div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import Vue from 'vue'
export default {
name: "Cloud",
data () {
return {
MessageDisplay: 'display:none', //弹出框显示
LoginDisplay: 'display:none', //登陆弹出框
LoginError: '',
MessageMsg: '', //弹出框消息
Loading: false,
info: {
m_id: 0,
charac_no: 0,
charac_name: '',
cz_level: [],
roleinfo: {},
plugGrowthInfo: {},
lb: {},
level_wc_count: 0,
time: '',
blood: 0,
},
login_info: {
username: '',
password: '',
role: '',
},
itme_show_data: [], //展示物品用
role_data: [],
};
},
created () {
this.getInfo()
},
methods: {
//登陆
login () {
if (this.Loading == true) {
return false
}
this.Loading = true
this.axios({
// 默认请求方式为get
method: 'post',
url: Vue.prototype.config.webplugurl + '/PlugLogin',
// 传递参数
data: {
key: this.$route.query.key,
keyid:this.$route.query.id,
username: this.login_info.username,
password: this.login_info.password,
role: this.login_info.role,
},
responseType: 'json'
}).then(response => {
this.Loading = false
if (response.data.code == 200) {
this.LoginDisplay = 'display:none'
this.getInfo()
} else {
this.LoginError = response.data.msg
}
}).catch(error => {
this.Loading = false
// 请求失败，
console.log(error)
});
},
//获取基本配置信息
getInfo () {
if (this.Loading == true) {
return false
}
this.Loading = true
this.axios({
// 默认请求方式为get
method: 'post',
url: Vue.prototype.config.webplugurl + '/growth/getGrowthInfo',
// 传递参数
data: {
key: this.$route.query.key,
keyid:this.$route.query.id,
},
responseType: 'json'
}).then(response => {
this.Loading = false
if (response.data.code == 200) {
this.info = response.data.info
} else if (response.data.code == 300) {
this.PopMessage(true, response.data.msg)
} else {
this.PopMessage(true, response.data.msg)
}
}).catch(error => {
this.Loading = false
// 请求失败，
console.log(error)
});
},
//获取角色
getRole () {
if (this.Loading == true) {
return false
}
this.Loading = true
this.axios({
// 默认请求方式为get
method: 'post',
url: Vue.prototype.config.webplugurl + '/getRole',
// 传递参数
data: {
key: this.$route.query.key,
keyid:this.$route.query.id,
username: this.login_info.username,
password: this.login_info.password,
},
responseType: 'json'
}).then(response => {
this.Loading = false
if (response.data.code == 200) {
this.role_data = response.data.info
} else {
this.LoginError = response.data.msg
}
}).catch(error => {
this.Loading = false
// 请求失败，
console.log(error)
});
},
//提交
receive (level, type) {
if (this.Loading == true) {
return false
}
this.Loading = true
this.axios({
// 默认请求方式为get
method: 'post',
url: Vue.prototype.config.webplugurl + '/growth/ReceiveGrowth',
// 传递参数
data: {
key: this.$route.query.key,
keyid:this.$route.query.id,
level: level,
retype: type,
},
responseType: 'json'
}).then(response => {
this.Loading = false
if (response.data.code == 200) {
this.PopMessage(true, response.data.msg)
this.getInfo()
} else {
this.PopMessage(true, response.data.msg)
}
}).catch(error => {
this.Loading = false
// 请求失败，
console.log(error)
});
},
//弹出框状态
PopMessage (type, msg) {
this.MessageDisplay = "display:none"
this.MessageMsg = msg
if (type == true) {
this.MessageDisplay = "display:block"
} else {
this.getInfo()
}
},
//关闭窗口
CloseAlert () {
this.PopMessage(false, '')
},
CloseAlert2 () {
this.MessageDisplay = "display:none"

Object.assign(this.$data, this.$options.data())
},
lj_info ($event, itmes) {
this.itme_show_data = itmes
var container = document.getElementsByClassName("iteminfo");
if (typeof (container) != "undefined") {
container[0]
var x = $event.x
var y = $event.y

if (x > 1167) {
console.log("11")
container[0].style.left = (x - 77) + 'px'
} else {
container[0].style.left = (x - 73) + 'px'
}
container[0].style.top = y + 'px'
container[0].style.display = 'block'
let item_h = container[0].offsetHeight + container[0].style.top;
if (item_h > 435) {
y -= (item_h - 435);
container[0].style.top = y + 'px'
}
}
},
removeActive ($event) {
console.log($event)
var container = document.getElementsByClassName("iteminfo");
if (typeof (container) != "undefined") {
container[0].style.display = 'none'
}
},
},
};
</script>

<style scoped>
@import "../../../public/static/plug/growth/static/css/game.css";
</style>

<style>
#root,
body,
html {
    min-width: 0px;
}
</style>